import { Component, OnInit } from '@angular/core';
import { TnsService } from '../../../services/tns/tns.service';
import { ApiService } from '../../../services/api/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'tns-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent implements OnInit {

  customerForm!: FormGroup;

  constructor(
    public tnsService: TnsService,
    private _apiService: ApiService,
    private fb: FormBuilder,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    // Initialiser le formulaire avec des valeurs par défaut
    this.customerForm = this.fb.group({
      lastname: [{ value: '', disabled: true }, [Validators.required]],
      firstname: [{ value: '', disabled: true }, [Validators.required]],
      birthdate: [{ value: '', disabled: true }, [Validators.required]]
    });

    // Écouter les changements sur `selectedFile`
    this.tnsService.selectedFile$.subscribe((file) => {
      if (file) {
        this.updateForm(file);
      }
    });

    this.tnsService.getPI(this.tnsService.selectedFile?.lastTrustAndSignClientFiles?.identity_document[0]);
  }

  updateForm(file: any) {
    const isSuspended = file?.lastTrustAndSignClientFiles?.state === 'SUSPENDED';

    // Mettre à jour les valeurs du formulaire et l’état des champs
    this.customerForm.patchValue({
      lastname: file?.customer?.lastname || '',
      firstname: file?.customer?.firstname || '',
      birthdate: file?.customer?.birthdate
        ? this.formatDate(file.customer.birthdate)
        : ''
    });

    if (isSuspended) {
      this.customerForm.enable(); // Activer les champs si l'état est `SUSPENDED`
    } else {
      this.customerForm.disable(); // Désactiver les champs sinon
    }
  }

  parseDateToYYYYMMDD(dateString: string): string {
    const [day, month, year] = dateString.split('/').map(Number); // Divise et convertit chaque partie en nombre
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`; // Formate en YYYY-MM-DD
  }

  updateCustomerInfo() {
    if (this.customerForm.valid) {
      const formValues = this.customerForm.value;

      const data = {
        lastname: formValues.lastname,
        firstname: formValues.firstname,
        birthdate: this.parseDateToYYYYMMDD(formValues.birthdate) // Conversion en format ISO
      };

      this._apiService.post(`customers/${this.tnsService.selectedFile.customer.id}`, data).subscribe(
        (response) => {
          this.messageService.add({severity:'success', summary:'Mise à jour du client', detail:'Les informations du client ont été mises à jour', life: 10000});
          this.tnsService.updateTNSClientFile(data);
        },
        (error) => {
          console.error('Erreur lors de la mise à jour des données :', error);
        }
      );
    } else {
      console.error('Formulaire invalide');
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }
}
