<div class="layout-sidebar" [class.sidebarOpened]="sidebarService.sidebarVisible()">
  <div class="layout-menu-container">
    <div class="sidebar-header">
      <img src="/assets/images/logo-onati-blanc.png" alt="Logo Vini" class="img-fluid" width="80%" style="max-width: 50px;">
    </div>
    <ul class="layout-menu">
      <li class="layout-root-menuitem" routerLinkActive="active">
        <ul>
          <li [routerLinkActive]="'active'">
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/dashboard'">
              <i class="layout-menuitem-icon fa-regular fa-gauge"></i> <span class="layout-menuitem-text">Tableau de bord</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="layout-root-menuitem" routerLinkActive="active">
        <div class="layout-menuitem-root-text" routerLinkActive="active">
          Dossiers Clients
        </div>
        <ul>
          <li [routerLinkActive]="'active'">
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/dossiers'">
              <i class="layout-menuitem-icon fa-regular fa-sim-cards"></i> <span class="layout-menuitem-text">Dossiers</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="layout-root-menuitem" routerLinkActive="active">
        <div class="layout-menuitem-root-text" routerLinkActive="active">
          Utilisateurs
        </div>
        <ul>
          <li [routerLinkActive]="'active'">
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/users/admin'">
              <i class="layout-menuitem-icon fa-regular fa-user-crown"></i> <span class="layout-menuitem-text">Administrateur</span>
            </a>
          </li>
          <li [routerLinkActive]="'active'">
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/users/agents'">
              <i class="layout-menuitem-icon fa-regular fa-user-headset"></i> <span class="layout-menuitem-text">Agents</span>
            </a>
          </li>
          <li [routerLinkActive]="'active'">
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/users/partenaires'">
              <i class="layout-menuitem-icon fa-regular fa-user-cowboy"></i> <span class="layout-menuitem-text">Partenaires</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="layout-root-menuitem" routerLinkActive="active">
        <div class="layout-menuitem-root-text" routerLinkActive="active">
          Tirage au sort
        </div>
        <ul>
          <li>
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/gifts'">
              <i class="layout-menuitem-icon fa-light fa-gift"></i> <span class="layout-menuitem-text">Lots</span>
            </a>
          </li>
          <li [routerLinkActive]="'active'">
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/draw'">
              <i class="layout-menuitem-icon fa-regular fa-dice"></i> <span class="layout-menuitem-text">Tirages</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="layout-root-menuitem" routerLinkActive="active">
        <div class="layout-menuitem-root-text" routerLinkActive="active">
          Réglages
        </div>
        <ul>
          <li [routerLinkActive]="'active'">
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/settings/main'">
              <i class="layout-menuitem-icon fa-regular fa-sliders"></i> <span class="layout-menuitem-text">Général</span>
            </a>
          </li>
          <li [routerLinkActive]="'active'">
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/settings/sms-message'">
              <i class="layout-menuitem-icon fa-regular fa-message-sms"></i> <span class="layout-menuitem-text">Sms de relance</span>
            </a>
          </li>
          <li [routerLinkActive]="'active'">
            <a class="p-element p-ripple" (click)="sidebarService.toggle()" [routerLink]="'/settings/localization'">
              <i class="layout-menuitem-icon fa-regular fa-language"></i> <span class="layout-menuitem-text">Traduction</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
