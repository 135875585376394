import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { AuthService } from '../../services/auth/auth.service';
import { TitleService } from '../../services/title/title.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  user: any;
  currentTitle = 'Titre par défaut';

  constructor(
    public sidebarService: SidebarService,
    private _authService: AuthService,
    private titleService: TitleService // Injection du service
  ) {}

  ngOnInit() {
    this.user = this._authService.authenticated();

    // Souscription au service TitleService
    this.titleService.currentTitle$.subscribe((title) => {
      this.currentTitle = title;
    });
  }

  logout() {
    this._authService.logout();
  }
}
