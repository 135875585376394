<div class="mb-5">
  <h5>Données saisies par le client</h5>
  <div class="card p-3">
    <form [formGroup]="customerForm" (ngSubmit)="updateCustomerInfo()">
      <div class="row mb-3 align-items-center">
        <label for="lastname" class="col-sm-4 col-form-label text-end">Nom :</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="lastname" formControlName="lastname" />
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <label for="firstname" class="col-sm-4 col-form-label text-end">Prénom :</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="firstname" formControlName="firstname" />
        </div>
      </div>
      <div class="row mb-3 align-items-center">
        <label for="birthdate" class="col-sm-4 col-form-label text-end">Date de naissance :</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="birthdate" formControlName="birthdate" placeholder="JJ/MM/AAAA" />
        </div>
      </div>
      <div class="text-end" *ngIf="tnsService.selectedFile?.lastTrustAndSignClientFiles?.state === 'SUSPENDED'">
        <button type="submit" class="btn btn-primary" [disabled]="!customerForm.valid">
          Enregistrer
        </button>
      </div>
    </form>
  </div>
</div>
<ng-container *ngIf="tnsService.selectedFile?.update_by_partner">
  <h5 style="text-transform: uppercase;">{{ tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1]['document_type'] }}</h5>
  <div class="card">
    <div class="row">
      <div class="col-12">
        Numéro de pièce : <br/>
        <b>{{ tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1]['document_number'] }}</b>
      </div>
        <div class="col-12">
          <p-image *ngIf="tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1]['document_url'] && tnsService.getTypeMedia(tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1]) !== 'pdf'" [src]="tnsService.getImage(tnsService.selectedFile?.customer['identity_documents'][tnsService.selectedFile?.customer['identity_documents'].length - 1])" width="100%"  [preview]="true" />
        </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="tnsService?.report?.clientFile?.participants[0].identityChecks">
  <h5 style="text-transform: uppercase;">{{ tnsService?.report?.clientFile?.participants[0]?.identityChecks[0]?.idDocument?.label }}</h5>
  <div class="card">
    <div class="row">
      <div class="col-12">
        <div>Nom <b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.informations?.holder?.birthName }}</b></div>
        <div>Prénom <b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.informations?.holder?.firstName }}</b></div>
        <div>Date de naissance <b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.informations?.holder?.birthDate | date:'dd/MM/yyyy' }}</b></div>
      </div>
      <ng-container *ngFor="let identityDocument of tnsService.selectedFile?.lastTrustAndSignClientFiles?.identity_document">
        Numéro de pièce : <br/>
        <div class="col-12" *ngIf="identityDocument.document_type === 'PASSPORT'">
          <div>Ligne 1<br/><b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.mrz?.line1 }}</b></div>
          <div>Ligne 2<br/><b>{{ tnsService?.report?.clientFile?.participants[0].identityChecks[0].idDocument?.data?.mrz?.line2 }}</b></div>
        </div>
        <div class="col-12">
          <iframe [src]="tnsService.getPI(identityDocument)" width="100%" height="600px" *ngIf="tnsService.getTypeMedia(identityDocument) === 'pdf'"></iframe>
          <p-image *ngIf="tnsService.getTypeMedia(identityDocument) !== 'pdf'" [src]="tnsService.getImage(identityDocument)" width="100%"  [preview]="true" />
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
