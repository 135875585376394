<div id="sidebarContent" [class.active]="sidebarContentIsVisible">
  <div class="header bg-body-secondary px-3 py-2 position-fixed">
    <div class="row">
      <div class="col-6">
        <div class="close d-inline-block" (click)="sidebarContentIsVisible = false; updateSidebarContentVisibility.emit(false)">
          <i class="fa-solid fa-solid fa-xmark"></i>
        </div>
        <p-tag class="mt-3" [severity]="tnsService.selectedFile?.update_by_partner ? 'success' : tnsService.selectedFile?.lastTrustAndSignClientFiles?.statusColor" [value]="tnsService.selectedFile?.update_by_partner ? 'TERMINÉ' : tnsService.selectedFile?.lastTrustAndSignClientFiles?.stateName" />
        Dossier <b>{{ tnsService.selectedFile?.customer?.lastname }} {{ tnsService.selectedFile?.customer?.firstname }}</b>
      </div>
      <div class="col-6 text-end">
        <button class="btn btn-success" (click)="showAcceptedModal()" *ngIf="tnsService.selectedFile?.lastTrustAndSignClientFiles?.state == 'SUSPENDED'">Accepter</button>
        <button class="btn btn-danger ml-2" (click)="showRejectedModal()" *ngIf="tnsService.selectedFile?.lastTrustAndSignClientFiles?.state == 'SUSPENDED'">Rejeter</button>
        <button *ngIf="!tnsService.selectedFile?.update_by_partner" class="btn btn-primary ml-2" (click)="tnsService.updateClientFile()">Mettre à jour</button>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="row mb-5">
      <div class="col-12 col-lg-6">
        <div class="tns-col">
          <div class="py-4 pl-4">
            <tns-identity></tns-identity>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 py-0">
        <div class="tns-col">
          <div class="timeline-container">
            <ng-container *ngIf="tnsService?.selectedFile?.update_by_partner">
              <div class="timeline-item" *ngFor="let event of tnsService?.selectedFile?.partnerEvents">
                  <div class="timeline-circle"></div>
                  <div class="timeline-content" [class.bg-danger-subtle]="event.state == 'KO'" [class.bg-success-subtle]="event.state != 'KO' && event.type != 'PARTNER'" [class.bg-info-subtle]="event.state != 'KO' && event.type == 'PARTNER'">
                    <tns-event [event]="event" [customer]="tnsService.selectedFile?.customer"></tns-event>
                  </div>
              </div>
            </ng-container>
            <div class="timeline-item" *ngFor="let event of tnsService?.selectedFile?.lastTrustAndSignClientFiles?.events">
                <div class="timeline-circle"></div>
                <div class="timeline-content" [class.bg-danger-subtle]="event.state == 'KO'" [class.bg-success-subtle]="event.state != 'KO'">
                  <tns-event [event]="event" [customer]="tnsService.selectedFile?.customer"></tns-event>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- à supprimer en pré-prod -->
<p-dialog header="Rejet de la pièce" [modal]="true" [(visible)]="tnsService.showModalRejectClientFile" [style]="{ width: '50dvw' }">
  <span class="p-text-secondary block mb-2">
    Vous vous apprêtez à rejeter la pièce de <b>{{ tnsService.selectedFile?.customer?.lastname }} {{ tnsService.selectedFile?.customer?.firstname }}</b>.<br/>
    <p>
      Le client recevra un sms l'indiquant de procéder à nouveau à son identification.
    </p>
    <span class="text-warning d-none">Rappel : Un SMS peut contenir un maximum de 160 caractères.</span>
  </span>

  <!-- Conteneur principal en deux colonnes -->
  <div class="row mb-3" style="display: none;">

    <!-- Colonne de gauche : Formulaire avec le message complet -->
    <div class="col-12" style="flex: 1; padding-right: 1rem;">
      <label for="completeMessage" class="form-label">Message complet <span style="font-size: 10px;">({{ fullMessage.length }} caractères)</span></label>
      <textarea [(ngModel)]="fullMessage" id="completeMessage" class="form-control" style="height: 250px; width: 100%;" (input)="updateMessageSegments()"></textarea>
    </div>

    <!-- Colonne de droite : Liste des erreurs et avertissements -->
    <div class="col-12" style="flex: 1; padding-left: 1rem;">
      <label class="form-label">Rappel des anomalies</label>
      <ul class="error-warning-list bg-danger-subtle p-0" style="list-style: none;">
        <li class="py-2 px-3 border-bottom-1 border-light" *ngFor="let error of errorSegments">
          {{ error }}
        </li>
        <li class="py-2 px-3 border-bottom-1 border-light" *ngFor="let warning of warningSegments">
          {{ warning }}
        </li>
      </ul>
    </div>
  </div>

  <div class="row d-none">
    <div class="col-12">
      Le message complet sera envoyé en {{ messageFormArray.length }} SMS.
    </div>
    <div class="col-12">
      <div class="split-messages" style="margin-top: 1rem;">
        <div *ngFor="let control of messageFormArray.controls; let i = index" class="mb-2">
          <label [for]="'messageSegment' + i" class="form-label">
            SMS {{ i + 1 }}
            <span class="badge" [ngClass]="getCounterColorClass(control.value.length)" style="font-size: 10px;">
              ({{ control.value.length }}/160) caractères
            </span>
          </label>
          <textarea
            [formControl]="control"
            [id]="'messageSegment' + i"
            class="form-control"
            (input)="adjustHeight($event)"
            style="resize: none; overflow: hidden;"
          ></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-content-end gap-2">
    <p-button class="rounded" label="Annuler" severity="secondary" (onClick)="tnsService.showModalRejectClientFile = false" />
    <p-button class="rounded" label="Rejeter" severity="danger" (onClick)="tnsService.rejectClientFile(fullMessage)" />
  </div>
</p-dialog>

<p-dialog header="Acceptation de la pièce" [modal]="true" [(visible)]="tnsService.showModalAcceptClientFile" [style]="{ width: '50dvw' }">
  <span class="p-text-secondary block mb-2">
    Vous vous apprêtez à accepter la pièce de <b>{{ tnsService.selectedFile?.customer?.lastname }} {{ tnsService.selectedFile?.customer?.firstname }}</b>.<br/>
    <p>
      Vérifiez les informations du client avant de confirmer l'acceptation du dossier et d'envoyer le SMS au client.<br/>
    </p>
  </span>

  <div class="flex justify-content-end gap-2">
    <p-button class="rounded" label="Annuler" severity="secondary" (onClick)="tnsService.showModalAcceptClientFile = false" />
    <p-button class="rounded" label="Accepter" severity="success" (onClick)="tnsService.acceptClientFile()" />
  </div>
</p-dialog>

<p-dialog header="Acceptation de la pièce" [modal]="true" [(visible)]="tnsService.showModalAcceptClientFileAfterUpdate" [style]="{ width: '50dvw' }">
  <span class="p-text-secondary block mb-2">
    Vous avez mis à jour des informations personnelles de <b>{{ tnsService.selectedFile?.customer?.lastname }} {{ tnsService.selectedFile?.customer?.firstname }}</b>.<br/>
    <p>
      Souhaitez-vous accepter son dossier?.<br/>
    </p>
  </span>

  <div class="flex justify-content-end gap-2">
    <p-button class="rounded" label="Annuler" severity="secondary" (onClick)="tnsService.showModalAcceptClientFile = false" />
    <p-button class="rounded" label="Accepter" severity="success" (onClick)="tnsService.acceptClientFile()" />
  </div>
</p-dialog>
